import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

import { Title } from './style'
import Heading from '../common/heading'
import { ButtonFromBackend } from '../common/button'
import { authorize, isLoggedIn } from '../../services/authentication'
import { Polygon } from '../../images/polygon'
import centeredImage from '../../images/map-centered.png'

const Hero = ({
  titleText,
  titleTag,
  subtitleText,
  subtitleTag,
  buttons,
  image,
  notification,
}) => {
  return (
    <>
      <div
        className={`
      w-full  
      block
      relative
      overflow-hidden
      ${image && 'bg-cover  bg-no-repeat'}
      ${
        notification
          ? `
      h-full

      lg:h-[calc(100vh-8.25rem)]
      `
          : `
      h-full
    
      lg:h-[calc(100vh-5rem)]
      `
      }
      `}
        css={`
          ${image && `background-image: url(${image.sourceUrl});`}
        `}
      >
        {!image && (
          <div
            className={`w-screen z-0 hidden lg:block absolute lg:-top-[18rem] lg:scale-[0.5] lg:right-[20rem] xl:-top-[10rem] xl:scale-[0.8] xl:right-[30rem] 2xl:-top-20 2xl:right-96 2xl:scale-90 3xl:scale-[0.9] 3xl:-top-20 3xl:right-[24rem] 4xl:-top-10 4xl:-right-[0rem] 4xl:scale-[1] 5xl:-top-10 5xl:scale-[1] 5xl:right-[4rem]
              ${notification && 'pb-0 z-0'}
              `}
          >
            <Polygon />
          </div>
        )}
        <div
          className={`h-full flex flex-col xl-container mx-auto items-start justify-start lg:justify-end pt-8 lg:pt-0 relative
          ${!image && 'pb-0 md:pb-0 3xl:pb-40'}
           ${notification ? 'pb-16 md:pb-10 lg:pb-0' : 'pb-44 md:pb-36 lg:pb-0'}
          `}
        >
          <div className={'overflow-hidden h-max mb-6 md:mb-3 lg:mb-8'}>
            <Title component={titleTag}> {titleText} </Title>
          </div>
          <Heading
            component={subtitleTag}
            className={
              'text-left text-base md:text-[19px] md:leading-7 lg:text-[21px] lg:leading-8 max-w-3xl [&>a]:border-b-2 [&>a]:border-orange-main hover:[&>a]:border-orange-lightHover'
            }
          >
            {parse(subtitleText)}
          </Heading>
          <div
            className={
              'flex flex-nowrap w-full justify-start gap-x-2 pt-6 lg:py-8 z-[25]'
            }
          >
            {isLoggedIn() && (
              <>
                <button
                  className={
                    'text-center font-medium w-max  pt-[14px] pb-[16px] px-10  md:pt-[17.5px]  md:pb-[19.5px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-orange-main text-white hover:bg-orange-light disabled:bg-gray-200 disabled:text-gray-700'
                  }
                  onClick={() => authorize()}
                >
                  Gå til Proteket Online
                </button>
              </>
            )}
            {buttons && !isLoggedIn() && (
              <>
                {buttons.map((item, index) => {
                  return (
                    <ButtonFromBackend
                      key={index}
                      path={item.link.url}
                      variant={item.variant}
                      isExternal={false}
                      target={item.link.target}
                      islarge={'true'}
                    >
                      {item.link.title}
                    </ButtonFromBackend>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>

      {!image && (
        <img
          src={centeredImage}
          alt={'Proteket kart'}
          className={
            'h-[40vh] sm:h-[50vh] md:h-[55vh] block lg:hidden object-cover scale-[1.8] md:scale-[1.6] pb-10 pt-8 sm:pt-12 md:pt-24 z-20'
          }
        />
      )}
    </>
  )
}

export default Hero

Hero.propTypes = {
  titleText: PropTypes.string,
  titleTag: PropTypes.string,
  subtitleText: PropTypes.string,
  subtitleTag: PropTypes.string,
  buttons: PropTypes.array,
  image: PropTypes.object,
}